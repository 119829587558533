import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/auth/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { authOnly: false }
  },
  
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/LoginView.vue"
      ),

      meta: { authOnly: false }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/RegisterView.vue"
      ),

      meta: { authOnly: false }
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/auth/ForgotPassword.vue"
      ),
      meta: { authOnly: false }
  },
  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/auth/OTPVerification.vue"),
      meta: { authOnly: false }
  },
  
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/auth/ResetPassword.vue"
      ),
      meta: { authOnly: false }
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/DashboardView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/TransactionsView.vue"),
      meta: { authOnly: true }
  },
   
 
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/WalletView.vue"),
      meta: { authOnly: true }
  },

  
  {
    path: "/fund-transfer",
    name: "FundTransfer",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/FundTransfer.vue"),
      meta: { authOnly: true }
  },

   
  {
    path: "/principlewallet",
    name: "principlewallet",
    component: () =>
      import(
        /* webpackChunkName: "principlewallet" */ "../views/wallet/PrincipleWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/withdrawwallet",
    name: "withdrawwallet",
    component: () =>
      import(
        /* webpackChunkName: "withdrawwallet" */ "../views/wallet/WithdrawWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/depositwallet",
    name: "depositwallet",
    component: () =>
      import(
        /* webpackChunkName: "depositwallet" */ "../views/wallet/DepositWallet.vue"
      ),
      meta: { authOnly: true }
  },
  {
    path: "/equalitywallet",
    name: "equalitywallet",
    component: () =>
      import(
        /* webpackChunkName: "equalitywallet" */ "../views/wallet/EqualityWallet.vue"
      ),
      meta: { authOnly: true }
  },

  {
    path: "/deposit",
    name: "deposit",
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/DepositView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/WithdrawView.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/activate",
    name: "activate",
    component: () =>
      import(/* webpackChunkName: "activate" */ "../views/ActivateView.vue"),
      meta: { authOnly: true }
  },
   
  {
    path: "/termcondition",
    name: "termcondition",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TermCondition.vue"),
      meta: { authOnly: false }
  },
  // {
  //   path: "/aboutus",
  //   name: "aboutus",
  //   component: () =>
  //     import(/* webpackChunkName: "terminate" */ "../views/AboutusView.vue"),
  //     meta: { authOnly: true }
  // },
   
   

  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SecurityView.vue"),
      meta: { authOnly: true }
  },

  
  
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ProfileView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/members/:member?",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/MembersView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/level-income",
    name: "LevelIncome",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/report-income/LevelIncome.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/direct-income",
    name: "DirectIncome",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/report-income/DirectIncome.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/roi-income",
    name: "RoiIncome",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/report-income/RoiIncome.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/level-members",
    name: "LevelMembers",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/LevelMembers.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/direct-members",
    name: "DirectMembers",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/DirectMembers.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/generation",
    name: "GenerationTree",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/GenerationTree.vue"),
      meta: { authOnly: true }
  },
   
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");
  const isAuth = to.matched.some((record) => record.meta.authOnly);
  
  if(to.fullPath == '/termcondition'){
    return next();
  }
   

  if (isAuth && !loggedIn) {
    return next({ path: "/" });
  } else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } else if (!isAuth && !loggedIn) {
    return next();
  }
  next();
});

export default router;
